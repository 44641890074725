<template>
  <div>
    <h1>Animales</h1>
    <b-container>
      <Breadcrumb :items_prop="itemsBreadcrumb"></Breadcrumb>
      <!--
      <b-button v-b-toggle.sidebar-right>Toggle Sidebar</b-button>-->

      <SearchOptions @buscar="buscar2" :busqueda="busqueda"></SearchOptions>


      <PaginadoInfo
        :num_registros_prop="animales.length"
        :paginado_prop="paginado"
      ></PaginadoInfo>

      <b-card-group class="d-flex flex-wrap">
        <b-card
          :key="id"
          v-for="(animal, id) in animales"
          border-variant="light"
          img-top
          :footer="animal.nombre"
          footer-tag="div"
        >
          <router-link
            class="stretched-link" 
            :to="{
              name: 'animal',
              params: { nombre: animal.nombre, id: animal.id },
            }"
          >
            <b-img-lazy
              class="card-img"
              fluid-grow
              :src="
                animal.id_foto != 0
                  ? url_web + animal.src_thumbnail_square + '?' + animal.id_foto
                  : animal.src_thumbnail_square
              "
              :alt="animal.src_thumbnail_square"
            />
          </router-link>
        </b-card>
      </b-card-group>

      <div v-if="paginado.total_registros== '0'" class="alert alert-info">
        No se encotraron resultados
      </div>

      <Paginado
        v-if="paginado.total_paginas > 1"
       :paginado_prop="paginado"
       :es_buscador_prop="EsBuscador"
       :qstring_prop="s"></Paginado>

      <Loading :bussy_prop="BussyLoadingAnimales"></Loading>
    </b-container>
    
  </div>
</template>

<script>
import { mapGetters, createNamespacedHelpers } from "vuex";
const { mapGetters: userMapGetters } = createNamespacedHelpers("user");
const { mapGetters: animalesMapGetters, mapActions: animalesMapActions } =
  createNamespacedHelpers("animales");
import Breadcrumb from "@/components/Breadcrumb";
import Loading from "@/components/Loading";
import Paginado from "@/components/Paginado";
import PaginadoInfo from "@/components/PaginadoInfo";
import SearchOptions from "@/components/SearchOptions";
export default {
  name: "Animales",
  data() {
    return {
      BussyLoading: false,
      s_: "",
    };
  },
  components: {
    Breadcrumb,
    Loading,
    Paginado,
    PaginadoInfo,
    SearchOptions,
  },
  props: {
    p: { default: 1 },
    s: { default: "" },
  },
  mounted() {
    this.loadData();
    //console.log("ola k ase en mounted, user_loged_in: " + this.user_loged_in);
    document.title = "Animales";
  },
  methods: {
    ...animalesMapActions(["GET_ANIMALES", "GET_BUSCAR_ANIMALES"]),
    ...animalesMapActions(["BUSSYLOADING"]),
    buscar() {
      event.preventDefault();
      if (this.s_ != "" && this.s_.length >= 3) {
        this.$router.push({
          name: "Animales buscar",
          params: { s: this.s_ },
        });
      }
    },
    buscar2(f) {
        this.$router.push({
          name: "Animales buscar",
          params: { s: f },
        });
    },
    loadData(){
      if (this.user_loged_in) {
            if (this.s && this.$route.params.s != '') {
              this.GET_BUSCAR_ANIMALES(this.s);
              //console.log("entro en buscar");
              this.s_ = this.s;
            }
            if (this.p != this.paginado.p 
              || (!this.EsBuscador && this.s != this.paginado.s)
              || (!this.EsBuscador &&this.animales.length ==0) ) {
              this.GET_ANIMALES(this.p);
              //console.log("entro en paginar");
            }
          }
    },
  },
  computed: {
    ...mapGetters(["url_site_local"]),
    ...userMapGetters([
      "user_loged_in",
      "usuario",
      "nombre_usuario",
      "url_web",
    ]),
    ...animalesMapGetters(["animales", "paginado",'busqueda']),
    ...animalesMapGetters(["BussyLoadingAnimales"]),
    itemsBreadcrumb() {
      var items = [
        {
          text: "Animales",
          to: "Animales",
        },
      ];
      return items;
    },
    EsBuscador(){
      return this.$route.name == "Animales buscar"
    }
  },
  watch: {
    user_loged_in: function () {
      if (this.user_loged_in) {
        this.loadData();
        //console.log("ola k ase en watch");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card-body {
  padding: 0 !important;
}
.card {
  margin: 0.5rem 0.3rem !important;
  min-width: 100%;
  @media (min-width: 288px) {
    max-width: 45%;
    min-width: 45%;
  }
  @media (min-width: 372px) {
    min-width: 47%;
  }
  @media (min-width: 576px) {
    min-width: 31%;
  }
  @media (min-width: 768px) {
    min-width: 31%;
  }
  @media (min-width: 992px) {
    min-width: 18%;
    max-width: 160px;
  }
  @media (min-width: 1200px) {
    min-width: 12%;
  }
}
.card:hover {
  border-color: #666 !important;
  .card-footer {
    background: #666;
    color: #fff;
  }
}
</style>