<template>
  <div class="overflow-auto mt-3" v-if="paginado_prop">
    <b-pagination-nav
      :link-gen="linkGen"
      :number-of-pages="paginado_prop.total_paginas"
      limit="10"
      use-router
    ></b-pagination-nav>
  </div>
</template>
<script>
export default {
  name: "Paginado",
  props: {
    paginado_prop: { default: false },
    es_buscador_prop: { default: false },
    qstring_prop: { default: '' }
  },
  methods: {
    linkGen(pageNum) {
      //return pageNum === 1 ? '?' : `?page=${pageNum}`
      //return pageNum === 1 ? '' : `/${pageNum}`
      if(this.es_buscador_prop){
        let query = this.qstring_prop +'&p='+pageNum
        return { 
          path: `/animales-buscar/${query}`
          };
      }
      else
      return { path: `/animales/${pageNum}` };
    },
  },
};
</script>

<style>
</style>