<template>
  <div v-if="paginado_prop && num_registros_prop">
    Mostrando 
    <span v-if="paginado_prop.inicio > 0">
        del {{ paginado_prop.inicio + 1 }} al
    </span>
     {{ paginado_prop.inicio + num_registros_prop}} de un total de {{ paginado_prop.total_registros}}
  </div>
</template>

<script>
export default {
  name: "PaginadoInfo",
  props:{
    paginado_prop: {default: false},
    num_registros_prop: {default: false},
  }
};
</script>

<style>
</style>