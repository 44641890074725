<template>
  <div>
    <div class="d-flex justify-content-between">
      <div class="p-2">
        <span
          v-for="(tag, index) in tags"
          :key="index"
          class="badge me-1 mb-1 p-2"
          :class="tag.css"
          >{{ tag.text }}</span
        >
      </div>
      <b-button  v-b-toggle.sidebar-right size="sm" variant="info"> 
        <b-icon icon="search"></b-icon>  Filtro</b-button>
    </div>

    <b-sidebar id="sidebar-right" title="Buscar" right shadow>
      <div class="px-3 py-2 text-start text-left">
      <b-tabs
        content-class="mt-2 mb-2"
        active-nav-item-class="font-weight-bold  text-light"
      >
        <b-tab title="General" active>
          <template #title>
            <button class="btn btn-rosita btn-sm">General</button>
          </template>
          <b-card
            >
            <div>
            <b-input-group>
              <b-form-input
                v-model="formu.nombre"
                type="search"
                class="w-auto mb-2"
                placeholder="Nombre"
                @input="addTag('Nombre: ' + formu.nombre, 'varios', 'nombre', formu.nombre)"   
              ></b-form-input>
              <b-input-group-append v-if="formu.nombre">
                <button @click="formu.nombre='';addTag('Nombre: ' + formu.nombre, 'varios', 'nombre', formu.nombre)" type="button" aria-label="Close" class="close text-dark mt-1"><svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="x" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-x b-icon bi"><g><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path></g></svg></button>
              </b-input-group-append>
            </b-input-group>
            
            <b-input-group>
              <b-form-input
                v-model="formu.id"
                type="search"
                class="w-auto mb-2"
                placeholder="ID"
                @input="addTag('ID: ' + formu.id, 'varios', 'id', formu.id)"
              ></b-form-input>
              <b-input-group-append  v-if="formu.id">
                <button @click="formu.id='';addTag('ID: ' + formu.id, 'varios', 'id', formu.id)" type="button" aria-label="Close" class="close text-dark mt-1"><svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="x" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-x b-icon bi"><g><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path></g></svg></button>
              </b-input-group-append>

            </b-input-group>
            </div>

            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="no-fallecidos"
                v-model="formu.omitir_fallecidos"
                @click="addTag('No fallecidos', 'varios', 'omitir_fallecidos', !formu.omitir_fallecidos)"
              />
              <label class="form-check-label" for="no-fallecidos">
                Omitir fallecidos
              </label>
            </div>

            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="no-externos"
                v-model="formu.omitir_externos"
                @click="addTag('No externos', 'varios', 'omitir_externos', !formu.omitir_externos)"
              />
              <label class="form-check-label" for="no-externos">
                Omitir externos
              </label>
            </div>

            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="actuales"
                v-model="formu.actuales"
                @click="addTag('Actuales', 'varios', 'actuales', !formu.actuales)"
              />
              <label class="form-check-label" for="actuales">
                Actuales <small>Sin fecha de salida.</small>
              </label>
              
            </div>
            
          </b-card>
        </b-tab>
        <b-tab title="Especie" >
          <template #title>
            <button class="btn btn-info btn-sm">Especie</button>
          </template>
          <b-card
            >Especie
            <div
              class="form-check form-switch"
              v-for="(item, index) in especies"
              :key="index"
            >
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                :id="'especie' + item.value"
                :checked="isSelected('especie', item.value)"
                @click="add(item, 'especie', $event)"
              />
              <label class="form-check-label" :for="'especie' + item.value">{{
                item.text
              }}</label>
            </div>
          </b-card>
        </b-tab>

        <b-tab title="Estado">
          <template #title>
            <button class="btn btn-warning btn-sm">Estado</button>
          </template>

          <b-card
            ><div
              class="form-check form-switch"
              v-for="(item, index) in estados"
              :key="index"
            >
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                :id="'estado' + item.value"
                :checked="isSelected('estado', item.value)"
                @click="add(item, 'estado', $event)"
              />
              <label class="form-check-label" :for="'estado' + item.value">{{
                item.text
              }}</label>
            </div></b-card
          >
        </b-tab>
        <b-tab title="Grupo">
          <template #title>
            <button class="btn btn-secondary btn-sm">Grupo</button>
          </template>
          <b-card
            ><div
              class="form-check form-switch"
              v-for="(item, index) in grupos"
              :key="index"
            >
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                :id="'grupo' + item.value"
                :checked="isSelected('grupo', item.value)"
                @click="add(item, 'grupo', $event)"
              />
              <label class="form-check-label" :for="'grupo' + item.value">{{
                item.text
              }}</label>
            </div></b-card
          >
        </b-tab>

        <b-tab title="Fechas">
          <template #title>
            <button class="btn btn-success btn-sm">Fechas</button>
          </template>
          <b-card> <strong class="d-block"> Fechas</strong> 
            <label>Con entrada en:</label>
            <div class="d-sm-flex ">
              
              <b-form-select 
              class="me-sm-2 mr-sm-2 mb-2"
              v-model="formu.entrada_anio"  :options="listaAnios"
              @change="addTag('Entrada: ' + formu.entrada_anio, 'fechas', 'entrada_anio', formu.entrada_anio)"
              ></b-form-select>

              <b-form-select 
              class="mb-2"
              v-model="formu.entrada_mes"  :options="options_meses"
              @change="addTagMes('Entrada: ', 'fechas', 'entrada_mes', formu.entrada_mes)"
              ></b-form-select>
            </div>
            
            <label>Con salida en:</label>
            <div class="d-sm-flex ">
              
              <b-form-select 
              class="me-sm-2 mr-sm-2 mb-2"
              v-model="formu.salida_anio"  :options="listaAnios"
              @change="addTag('Salida: ' + formu.salida_anio, 'fechas', 'salida_anio', formu.salida_anio)"
              ></b-form-select>

              <b-form-select 
              class="mb-2"
              v-model="formu.salida_mes"  :options="options_meses"
              @change="addTagMes('Salida: ', 'fechas', 'salida_mes', formu.salida_mes)"
              ></b-form-select>
            </div>



          </b-card>
        </b-tab>

      </b-tabs>
      <!---->
      <div class="d-sm-flex justify-content-between">
        <button 
          class="btn btn-info btn-block" 
          @click="submitBuscar()">
            Buscar
          </button>

          <button 
          class="btn btn-light btn-sm" 
          @click="limpiarFiltro()">
            Limpiar filtro
          </button>
      </div>
        
      </div>
    </b-sidebar>
  </div>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
const { mapGetters: baseMapGetters } = createNamespacedHelpers("base");

export default {
  name: "SearchOptions",
  data() {
    return {
      especie: [],
      estado: [],
      grupo: [],
      nombre: "",
      id: "",
      tags: [],
      omitir_fallecidos: true,
      formu: {
        especie: "",
        estado: "",
        grupo: "",
        nombre: "",
        id: "",
        omitir_fallecidos: '',
        omitir_externos: '',
        actuales: '',
        entrada_anio: '',
        entrada_mes: '',
        salida_anio: '',
        salida_mes: ''
      },
      options_meses: [
        { value: '', text: 'Mes' },
        { value: '01', text: 'enero' },
        { value: '02', text: 'febrero' },
        { value: '03', text: 'marzo' },
        { value: '04', text: 'abril' },
        { value: '05', text: 'mayo' },
        { value: '06', text: 'junio' },
        { value: '07', text: 'julio' },
        { value: '08', text: 'agosto' },
        { value: '09', text: 'septiembre' },
        { value: '10', text: 'octubre' },
        { value: '11', text: 'noviembre' },
        { value: '12', text: 'diciembre' },
      ]
    };
  },
  props: {
    busqueda: { default: {} },
  },
  watch: {
    especie_ides: function () {
      this.formu.especie = this.especie_ides;
    },
    estado_ides: function () {
      this.formu.estado = this.estado_ides;
    },
    grupo_ides: function () {
      this.formu.grupo = this.grupo_ides;
    },
    busqueda: function () {
      this.cargarBusqueda();
    },
    caracteristicas_cargadas: function () {
      this.cargarBusqueda();
    },
  },
  computed: {
    ...baseMapGetters(["especies", "estados", "grupos", 'caracteristicas_cargadas']),
    especie_ides() {
      let especies = [];
      this.especie.forEach((element) => {
        especies.push(element.value);
      });
      let especies_str = especies.join(",");
      return especies_str;
    },
    estado_ides() {
      let estados = [];
      this.estado.forEach((element) => {
        estados.push(element.value);
      });
      let estados_str = estados.join(",");
      return estados_str;
    },
    grupo_ides() {
      let grupos = [];
      this.grupo.forEach((element) => {
        grupos.push(element.value);
      });
      let grupos_str = grupos.join(",");
      return grupos_str;
    },
    formuOk() {
      if (
        this.formu.especie ||
        this.formu.estado ||
        this.formu.grupo ||
        this.formu.nombre ||
        this.formu.id ||
        this.formu.omitir_fallecidos ||
        this.formu.omitir_externos ||
        this.formu.actuales ||
        this.formu.entrada_anio ||
        this.formu.entrada_mes ||
        this.formu.salida_anio ||
        this.formu.salida_mes
      ) {
        return true;
      }
      return false;
    },
    listaAnios(){
      var today = new Date();
      var year = today.getFullYear();
      var year20 = year-20;

      let anios = [{ value: '', text: 'Año' },];
      for(let i=year; i>year20; i--){
        let val = { value: i, text: i }
        anios.push(val);
      }
      return anios
    },
  },
  methods: {
    submitBuscar() {
      if (this.formuOk) {
        let envio_arr = [""];
        if (this.formu.especie) envio_arr.push("especie=" + this.formu.especie);
        if (this.formu.estado) envio_arr.push("estado=" + this.formu.estado);
        if (this.formu.grupo) envio_arr.push("grupo=" + this.formu.grupo);
        if (this.formu.omitir_fallecidos) envio_arr.push("omitir_fallecidos=" + this.formu.omitir_fallecidos);

        if (this.formu.omitir_externos) envio_arr.push("omitir_externos=" + this.formu.omitir_externos);
        if (this.formu.actuales) envio_arr.push("actuales=" + this.formu.actuales);

        if (this.formu.id) envio_arr.push("id=" + this.formu.id);
        if (this.formu.nombre) envio_arr.push("nombre=" + this.formu.nombre);

        if (this.formu.entrada_anio) envio_arr.push("entrada_anio=" + this.formu.entrada_anio);
        if (this.formu.entrada_mes) envio_arr.push("entrada_mes=" + this.formu.entrada_mes);
        if (this.formu.salida_anio) envio_arr.push("salida_anio=" + this.formu.salida_anio);
        if (this.formu.salida_mes) envio_arr.push("salida_mes=" + this.formu.salida_mes);



        let envio = envio_arr.join("&");
        //this.$emit('buscar', this.formu)
        this.$emit("buscar", envio);
      }
    },
    add(item, lista, el) {
      let is_checked = el.target.checked;

      if (is_checked) {
        this[lista].push(item);
      } else {
        let index = this[lista].findIndex((it) => it.value === item.value);
        if (index > -1) {
          this[lista].splice(index, 1);
        }
      }

      this.addTag(item.text, lista, lista+item.value, is_checked);
    },
    addTag(texto, lista, id, val) {
      if (val) {
        let tag = { text: texto, css: "bg-" + lista, id: id };

        let index = this.tags.findIndex((it) => it.id === id);
        if (index > -1){
          this.tags[index].text = texto
        }
        else{
          this.tags.push(tag);
        }
      } else {
        let index = this.tags.findIndex((it) => it.id === id);
        if (index > -1) {
          this.tags.splice(index, 1);
        }
      }
    },
    addTagMes(texto, lista, id, val){
      let index = this.options_meses.findIndex((it) => it.value === val);
        if (index > -1){
          texto = texto + this.options_meses[index].text
          this.addTag(texto, lista, id, val)
        }
    },
    isSelected(lista, val) {
      let is_selected = false;
      let index = this[lista].findIndex((it) => it.value == val);
      if (index > -1) {
        is_selected = true;
        console.log(index + ' ' +lista+ ' '+ val + ' es ' + is_selected)
        //return true;
      } else {
          is_selected = false;
      }//return false;
      
      return is_selected;
    },
    cargarBusqueda(){
      if ("entrada_anio" in this.busqueda){
        let entrada_anio = this.busqueda['entrada_anio']
        this.formu.entrada_anio = entrada_anio
        this.addTag('Entrada: '+entrada_anio, 'fechas','entrada_anio', entrada_anio)
      }
      if ("entrada_mes" in this.busqueda){
        let entrada_mes = this.busqueda['entrada_mes']
        this.formu.entrada_mes = entrada_mes
        this.addTagMes('Entrada: ', 'fechas','entrada_mes', entrada_mes)
      }
      if ("salida_anio" in this.busqueda){
        let salida_anio = this.busqueda['salida_anio']
        this.formu.salida_anio = salida_anio
        this.addTag('Salida: '+salida_anio, 'fechas','salida_anio', salida_anio)
      }
      if ("salida_mes" in this.busqueda){
        let salida_mes = this.busqueda['salida_mes']
        this.formu.salida_mes = salida_mes
        this.addTagMes('Salida: ', 'fechas','salida_mes', salida_mes)
      }
      if ("id" in this.busqueda){
        let id = this.busqueda['id']
        this.formu.id = id
        this.addTag('id: '+id, 'varios','id', id)
      }
      if ("nombre" in this.busqueda){
        let nombre = this.busqueda['nombre']
        this.formu.nombre = nombre
        this.addTag('nombre: '+nombre, 'varios','nombre', nombre)
      }
      if ("omitir_fallecidos" in this.busqueda){
        let omitir_fallecidos = this.busqueda['omitir_fallecidos']
        this.formu.omitir_fallecidos = omitir_fallecidos
        this.addTag('No fallecidos', 'varios','omitir_fallecidos', omitir_fallecidos)
      }
      if ("omitir_externos" in this.busqueda){
        let omitir_externos = this.busqueda['omitir_externos']
        this.formu.omitir_externos = omitir_externos
        this.addTag('No externos', 'varios','omitir_externos', omitir_externos)
      }
      if ("actuales" in this.busqueda){
        let actuales = this.busqueda['actuales']
        this.formu.actuales = actuales
        this.addTag('Actuales', 'varios','actuales', actuales)
      }
      if ("estado" in this.busqueda) {
        this.busqueda["estado"].forEach((element) => {
          let index = this.estados.findIndex((it) => it.value == element);
          if (index > -1) {
            this.estado.push(this.estados[index]);
            console.log(element)
            this.addTag(this.estados[index].text, "estado", 'estado'+this.estados[index].value,true);
          }
        });
      }
      if ("especie" in this.busqueda) {
        this.busqueda["especie"].forEach((element) => {
          
          let index = this.especies.findIndex((it) => it.value == element);
          if (index > -1) {
            this.especie.push(this.especies[index]);
            this.addTag(this.especies[index].text, "especie", 'especie'+this.especies[index].value, true);
          }
        });
      }
      if ("grupo" in this.busqueda) {
        this.busqueda["grupo"].forEach((element) => {
          let index = this.grupos.findIndex((it) => it.value == element);
          if (index > -1) {
            this.grupo.push(this.grupos[index]);
            this.addTag(this.grupos[index].text, "grupo", 'grupo'+this.grupos[index].value, true);
          }
        });
      }
    },
    limpiarFiltro(){
      this.especie=[]
      this.estado=[]
      this.grupo=[]
      this.tags=[]
      this.formu.especie = ''
      this.formu.estado = ''
      this.formu.grupo = ''
      this.formu.nombre = ''
      this.formu.id = ''
      this.formu.omitir_fallecidos = ''
      this.formu.omitir_externos = ''
      this.formu.actuales = ''
      this.formu.entrada_anio = ''
      this.formu.entrada_mes = ''
      this.formu.salida_anio = ''
      this.formu.salida_mes = ''
    },
  },
};
</script>